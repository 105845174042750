import React from "react";
import { smartButtonItemClass } from "../../helpers/helperClasses";
import { cn } from "../../lib/utils";
import { useAppState } from "../../context/AppStateContext";

const ButtonSuggestionItem = ({ text }: any) => {
  const { state, dispatch } = useAppState();

  const getComputedStyleVariable = (variable: string) => {
    return getComputedStyle(document.documentElement)
      .getPropertyValue(variable)
      .trim();
  };
  const primaryColorValue = getComputedStyleVariable("--primary");
  const rgbaColor = primaryColorValue
    ? `rgba(${parseInt(primaryColorValue.slice(1, 3), 16)}, ${parseInt(
        primaryColorValue.slice(3, 5),
        16
      )}, ${parseInt(primaryColorValue.slice(5, 7), 16)}, 0.12)` // Adjust the alpha value as needed
    : "";



    const handleSmartButtonClick = () => {
    console.log("handleSmartButtonClicked")
  };

  return (
    <button
    onClick={handleSmartButtonClick}
      style={{
        backgroundColor: rgbaColor,
        color: `${state?.appTheme === "Light" ? "var(--primary)" : "#FFF"}`,
      }}
      className={cn(
        smartButtonItemClass,
        "aspect-auto h-9 px-3 flex-nowrap min-w-max text-sm font-medium  rounded-lg"
      )}
    >
      {text}
    </button>
  );
};

export default ButtonSuggestionItem;
