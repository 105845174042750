import { Music2 } from "lucide-react";
import { Play } from "lucide-react";
import React, { useState } from "react";
import MediaDrawer from "../drawer/MediaDrawer";
import { X } from "lucide-react";
import AudioPlayer from "../player/AudioPlayer";

const SuggestionItemRounded = ({
  img,
  type,
  title,
  description,
  videoURL,
  audioURL,
}: any) => {
  const [showModal, setShowModal] = useState(false);
  const [videoSource, setVideoSource] = useState("");
  const [openArticleDrawer, setOpenArticleDrawer] = useState<boolean>(false);

  const handleCardClick = () => {
    if (type === "video" || type === "audio") {
      setShowModal(true);
      if (type === "Video") {
        setVideoSource(videoURL);
      }
    } else if (type === "Article") {
      setOpenArticleDrawer(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleCloseArticles = () => {
    setOpenArticleDrawer(false);
  };

  return (
    <>
      <div
        className="min-w-[280px] min-h-[85px] h-max rounded-full relative before:absolute before:w-[200%] before:h-[500%] before:content-[''] border--beam before:animate-border-spin before:-left-[35%] before:-top-[150px] border overflow-hidden bg-white dark:bg-[#010a24]"
        onClick={handleCardClick}
      >
        <div className="w-[calc(100%-4px)] h-[calc(100%-4px)] left-[2px] top-[2px] absolute bg-white dark:bg-[#010a24] rounded-full  p-1 flex items-center gap-2">
          <div className="relative h-full w-auto aspect-square">
            <img
              src={img}
              className="h-full w-auto aspect-square object-cover rounded-full border border-slate-200"
            />
            {type === "video" ? (
              <div className="p-1 rounded-full bg-white  flex items-center justify-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border">
                <Play className="w-4 h-4" style={{ color: "var(--primary)" }} />
              </div>
            ) : type === "audio" ? (
              <div className="p-1 rounded-full bg-white  flex items-center justify-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border">
                <Music2
                  className="w-4 h-4"
                  style={{ color: "var(--primary)" }}
                />
              </div>
            ) : null}
          </div>
          <div className="space-y-1 pr-3">
            <span className="uppercase tracking-wider text-[0.58rem] bg-purple-200/60 dark:bg-purple-200/20 font-medium px-2 py-1 rounded-full text-purple-600/90 dark:text-purple-100 border border-purple-200/80 dark:border-purple-600/80">
              {type}
            </span>
            <h5 className="text-sm font-medium leading-tight dark:text-white">
              {title}
            </h5>

            {description && (
              <p className="text-xs text-slate-600 dark:text-slate-200 line-clamp-1">
                {description}
              </p>
            )}
          </div>
        </div>
      </div>

      <MediaDrawer isOpen={showModal} onClose={closeModal}>
        <div>
          <div className="flex items-start justify-between gap-3 mb-7">
            <h3 className="text-xl text-left font-semibold leading-6 text-gray-900 dark:text-white">
              {title}
            </h3>

            <button
              className="bg-slate-500  dark:bg-slate-400 text-white dark:text-black min-w-8 w-8 h-8 aspect-square flex items-center justify-center rounded-full"
              onClick={closeModal}
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          <div className="mb-4 md:mb-0">
            {type === "video" &&
            videoURL !== undefined &&
            videoURL !== "" &&
            !videoURL.includes("iframe") ? (
              videoURL.includes("vimeo") ? (
                <iframe
                  src={videoURL}
                  className="w-full h-auto aspect-video rounded-2xl"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  title={title}
                ></iframe>
              ) : (
                <video
                  src={videoSource}
                  controls
                  autoPlay
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "1rem",
                  }}
                />
              )
            ) : type === "audio" &&
              audioURL !== undefined &&
              audioURL !== "" ? (
              <div className="flex flex-col gap-10 items-center justify-center py-4">
                {/* <img
                  src={AudioPng}
                  alt=""
                  width={70}
                  height={70}
                  className="rounded-full"
                /> */}
                <AudioPlayer
                  image={img}
                  audioUrl={audioURL}
                  audioName={title}
                  showImage={true}
                />
                {/* <audio
                  className="audio-player w-full"
                  src={audioURL}
                  controls
                  autoPlay
                /> */}
              </div>
            ) : null}
          </div>
        </div>
      </MediaDrawer>
    </>
  );
};

export default SuggestionItemRounded;
