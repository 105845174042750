import React from "react";
import { chatTextAreaContainerClass } from "../../helpers/helperClasses";
import { useLanguage } from "../../context/LanguageContext";

const NotLoggedInButton = () => {
  const { translate } = useLanguage();
  const navigateToURL = (url: string) => {
    window.location.href = url;
  };

  return (
    <div className="max-w-6xl fixed bottom-0 left-0 md:left-1/2 md:-translate-x-1/2 w-full grid gap-0 z-10">
      <div className={chatTextAreaContainerClass}>
        <button
          style={{ backgroundColor: "var(--primary)" }}
          className="bg-primary dark:bg-primary/80 px-5 py-3 rounded-lg text-sm text-white mx-auto"
          onClick={() => {
            //Go to login SPA
            navigateToURL(
              `https://accounts.unitedwecare.com/?redirect=${window.location.href}`
            );
          }}
        >
          {/* INTERNATIONALIZATION */}
          {translate("LoginOrRegister")}
          {/* Login or Register */}
        </button>
      </div>
    </div>
  );
};

export default NotLoggedInButton;
