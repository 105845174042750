import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Send, Mic, Square, RefreshCw, LoaderCircle, X } from "lucide-react";

import { useAppState } from "../../context/AppStateContext";
import { ActionTypes } from "../../reducer/actionTypes";
import {
  fileUploadImage,
  isSafari,
  sendTranscriptToApi,
  soundMimeType,
} from "../../lib/utils";
import { useAuth } from "../../auth";
import {
  AddDocument,
  fetchConversation,
  fetchSTTWhisper,
  GetMyDocuments,
  SendUploadedDoc,
  TranslateWhisperTranslations,
} from "../../api/_request";

import { useLanguage } from "../../context/LanguageContext";

import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

import useSpeechToText from "react-hook-speech-to-text";

import {
  chatInputClass,
  chatTextAreaContainerClass,
  microphoneButtonClass,
  sendChatButtonClass,
  smartButtonItemClass,
  stopRecordingButtonClass,
} from "../../helpers/helperClasses";

import { convertBlobToBase64, sendMessageToApiCore } from "../../lib/utils";

import { addMessage } from "../../indexedDB/dbHelper";

import Cookies from "js-cookie";

/* Testing with Whisper */
// import OpenAI from "openai";
// const model = "whisper-1";

//FIREBASE
import { signInAnonymously } from "firebase/auth";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { auth, storage } from "../../config/firebaseConfig";
import dayjs from "dayjs";
import UploadFileDrop from "../../dropdowns/UploadFileDrop";
import SmartSuggestionsArea from "./SmartSuggestionsArea";
import NotLoggedInButton from "./NotLoggedInButton";

const ChatTextInputArea: React.FC = () => {
  const { state, dispatch } = useAppState();
  const { translate } = useLanguage();

  const MAX_ROW = 4;
  // const openai = new OpenAI({
  //   apiKey: process.env.REACT_APP_OPENAI_KEY,
  //   dangerouslyAllowBrowser: true,
  // });
  const { currentUser } = useAuth();
  const uniqueID = crypto.randomUUID().toString();

  const { browserSupportsSpeechRecognition } = useSpeechRecognition();

  const search = useLocation().search;
  const searchBarParams = new URLSearchParams(search);

  const token_url = searchBarParams.get("token");
  //Will use this to hide certain features for certain partners
  const appPartner = searchBarParams.get("appPartner");
  const cookie_token = Cookies.get("authToken");

  //Google Speech to text

  /*if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }*/

  //Local States
  const [inputValue, setInputValue] = useState<string>("");
  const [audioStates, setAudioStates] = useState<any>({
    showMic: true,
    showStop: false,
    showSend: true,
    audioRequestData: null,
    isRecordingStopped: false,
  });
  const [isRunningInIframe, setIsRunningInIframe] = useState<boolean>(false);

  //Experimental States for Whisper
  const [isListening, setIsListening] = useState<boolean>(false);
  const [permission, setPermission] = useState<boolean>(false);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
  const [tooltip, setTooltip] = useState<{
    text: string;
    x: number;
    y: number;
  } | null>(null);

  const [fileUploaded, setFileUploaded] = useState<string>("idle");
  const [uploadedFileLink, setUploadedFileLink] = useState<string | null>(null);

  /*-------------- EXPERIMENTAL BLOCK START ------------------ */

  /*--------------------------------------------------------------------------------------------------
  |   TO DO:                                                                                         |
  |   1. Have an hidden input file.                                                                  |
  |   2. After recording is stopped download file temporarily and simulate file select and send      |
  |   3. Send file to OPENAI Whisper                                                                 |
  |   4. Get the transcript and use that                                                             |
  --------------------------------------------------------------------------------------------------*/

  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const inputRefDesktop = useRef<HTMLTextAreaElement | null>(null);
  const mediaRecorderRef = useRef<any>(null);
  const audioChunksRef = useRef<any>([]);
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const fileRef = useRef<HTMLInputElement | null>(null);
  const submitBtnRef = useRef<HTMLButtonElement | null>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const smartSuggestionAreaRef = useRef<HTMLDivElement>(null);

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [startX, setStartX] = useState<number>(0);
  const [scrollLeft, setScrollLeft] = useState<number>(0);

  const [translationAPIState, setTranslationAPIState] =
    useState<string>("idle");

  const [updateUploadState, setUpdateUploadState] = useState<any>({
    uploadData: null,
    uploadState: false,
  });

  useEffect(() => {
    if (updateUploadState?.uploadState) {
      sendTranscriptToApi(
        updateUploadState?.uploadData,
        state,
        dispatch,
        setUpdateUploadState
      );
    }
  }, [updateUploadState, state?.chatHistory]);

  useEffect(() => {
    //Send Message to API
    handleMessageValue();
  }, [state.chatHistory]);

  useEffect(() => {
    // getMicrophonePermission();
    if (
      smartSuggestionAreaRef?.current !== null &&
      smartSuggestionAreaRef?.current !== undefined
    ) {
      dispatch({
        type: ActionTypes.SET_SUGGESTION_AREA_REF,
        payload: smartSuggestionAreaRef?.current,
      });
    }
  }, [smartSuggestionAreaRef?.current]);

  /*useEffect(() => {
    if (listening) {
      setInputValue(transcript);
    }
  }, [listening, transcript]);*/

  //UseEffect for handling textarea height as and when user is typing
  //Mobile Version
  useEffect(() => {
    if (inputRef.current) {
      const textarea = inputRef.current;

      // Temporarily reset rows to 1 for accurate measurement
      textarea.rows = 1;

      // Measure content height based on scrollHeight
      const contentHeight = textarea.scrollHeight;

      // Calculate rows needed based on line height (assuming 20px line height here, can be adjusted)
      const requiredRows = Math.min(
        MAX_ROW,
        Math.ceil(contentHeight / 50) // Divisor adjusted based on line height
      );

      // Set rows accordingly
      textarea.rows = requiredRows;

      // Check for empty content and reset rows to 1 if needed
      if (!textarea.value) {
        textarea.rows = 1;
      }
    }
  }, [inputValue, inputRef]);

  //UseEffect for handling textarea height as and when user is typing
  //Desktop Version
  useEffect(() => {
    if (inputRefDesktop.current) {
      const textarea = inputRefDesktop.current;

      // Temporarily reset rows to 1 for accurate measurement
      textarea.rows = 1;

      // Measure content height based on scrollHeight
      const contentHeight = textarea.scrollHeight;

      // Calculate rows needed based on line height (assuming 20px line height here, can be adjusted)
      const requiredRows = Math.min(
        MAX_ROW,
        Math.ceil(contentHeight / 50) // Divisor adjusted based on line height
      );

      // Set rows accordingly
      textarea.rows = requiredRows;

      // Check for empty content and reset rows to 1 if needed
      if (!textarea.value) {
        textarea.rows = 1;
      }
    }
  }, [inputValue, inputRefDesktop]);

  //Check if App is loaded in iFrame or not
  //Might use this for showing certain features
  useEffect(() => {
    if (isInIframe()) {
      console.log("React app is running inside an iframe");
      setIsRunningInIframe(true);
    } else {
      console.log("React app is not running inside an iframe");
      setIsRunningInIframe(false);
    }
  }, []);

  useEffect(() => {
    if (audioStates?.audioRequestData) {
      sendAudioToApi(audioStates?.audioRequestData);
    }
  }, [audioStates]);

  function isInIframe() {
    return window.frameElement !== null;
  }

  const getMicrophonePermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        setPermission(true);
        setStream(streamData);
      } catch (error: any) {
        console.log("ERROR", error.message);
      }
    } else {
      alert("MEdiaRecorder API not supported");
    }
  };

  const handleMessageValue = async () => {
    // console.log("handleMessageValue Called!");
    const lastMessage = state.chatHistory[state.chatHistory.length - 1];
    let messageValue: any;

    if (lastMessage === "loading") {
      // Perform the API call here
      const actualLastMessage = state.chatHistory[state.chatHistory.length - 2];

      //addMessage(actualLastMessage);
      if (actualLastMessage.type === "button_id") {
        messageValue = actualLastMessage.buttonID;
        sendMessageToApi(actualLastMessage.type, messageValue);
      } else if (
        actualLastMessage.type === "message" ||
        actualLastMessage.type === "signature"
      ) {
        messageValue = actualLastMessage.message;
        sendMessageToApi(actualLastMessage.type, messageValue);
      } else if (actualLastMessage.type === "audio") {
        messageValue = await convertBlobToBase64(audioStates.audioRequestData);
        sendMessageToApi(actualLastMessage.type, messageValue);
      } else if (actualLastMessage.type === "upload") {
        const completedResponse = await sendMessageToApi(
          "payload",
          "Upload_Processing"
        );

        if (completedResponse) {
          await sendDataToSTT(actualLastMessage, completedResponse);
        }
      }
    }
  };

  const sendDataToSTT = async (lastMessage: any, completedResponse: any) => {
    // console.log("actualLastMessage", lastMessage);
    try {
      const body = {
        image_url: lastMessage?.uploadedItemURL,
        lmm_prompt: "",
        llm_prompt: "",
        max_tokens: 0,
        temperature: 0,
        text: lastMessage?.message,
      };

      const responseData = await SendUploadedDoc(JSON.stringify(body));

      if (responseData?.status == 200) {
        setUpdateUploadState({
          uploadData: responseData?.data,
          uploadState: true,
        });

        const addBody = {
          uploaded_by: "user",
          user_id: currentUser?.UserId + "",
          creator_id: currentUser?.UserId + "",
          clinical_note_id: null,
          //datetime: "2024-08-16T14:17:23.990717",
          doctor_names: responseData?.data?.doctor_names || "",
          response: responseData?.data?.response,
          clinical_relevance: responseData?.data?.clinical_relevance,
          metadata: responseData?.data?.metadata,
        };

        const addDocResponse = await AddDocument(addBody);

        await FetchMyDocuments();
        // console.log("Before sendTranscriptToApi", state?.chatHistory);
      }
    } catch (error) {
      const newMessage = {
        _id: uniqueID,
        response: "server",
        data: "I'm sorry, I ran into a problem while uploading your document. Could you please try uploading again?",
        avatar: state.selectedFace,
      };

      // console.log("sendDataToSTT", newMessage);
      // console.log("newMessage", [...state.chatHistory, newMessage]);

      const uploadFailedResponse = await sendMessageToApi(
        "payload",
        "Upload_Failed"
      );

      //console.log("uploadFailedResponse", uploadFailedResponse)
    }
  };

  const FetchMyDocuments = async () => {
    try {
      const myDocsQueries = {
        userID: currentUser?.UserId + "",
        count: 10,
        page: 1,
        token: state?.authToken,
      };
      const documentsResponse = await GetMyDocuments(myDocsQueries);

      if (documentsResponse?.status == 200) {
        dispatch({
          type: ActionTypes.SET_MY_DOCUMENTS,
          payload: documentsResponse?.data?.data,
        });
        //setMyDocuments(documentsResponse?.data?.data);
        // setTotalPages(documentsResponse?.data?.total_pages);
        // setTotalCount(documentsResponse?.data?.total_count);
        dispatch({
          type: ActionTypes.SET_MY_DOCUMENTS_COUNT,
          payload: documentsResponse?.data?.total_count,
        });
      }
    } catch (error) {}
  };

  const onEnterClicked = () => {
    // Perform your desired actions here

    const newMessage = {
      _id: uniqueID,
      response: "self",
      type: uploadedFileLink ? "upload" : "message",
      message: inputValue,
      avatar: currentUser?.ProfileImage,
      uploadedItemURL: uploadedFileLink,
      uploadedItemImage: fileUploaded,
    };
    dispatch({
      type: ActionTypes.UPDATE_CHAT_HISTORY,
      payload: [...state.chatHistory, newMessage, "loading"],
    });

    setInputValue("");
    setFileUploaded("idle");
    setUploadedFileLink(null);
    // const activeElement = document.activeElement;
    // if (activeElement instanceof HTMLElement) {
    //   activeElement.blur();
    // }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter" && inputValue !== "") {
      event.preventDefault();
      // Call a function when Enter key is pressed
      onEnterClicked();
      setInputValue("");
    }
  };

  const handleExtraUpdatesComponent1 = (res: any, delay: any) => {
    //console.log("Additional handling for Component 2 after API response", res, delay);
    // Additional logic specific to Component 2, e.g., updating UI or state

    setAudioStates((prevData: any) => ({
      ...prevData,
      audioRequestData: null,
      isRecordingStopped: true,
    }));
  };

  const sendMessageToApi = async (key: string, value: any) => {
    const messageSentResponse = await sendMessageToApiCore(
      key,
      value,
      state,
      dispatch,
      handleExtraUpdatesComponent1
    );
    return messageSentResponse;
  };

  const startRecording = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error("getUserMedia not supported on your browser");
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: soundMimeType,
      });
      mediaRecorderRef.current.ondataavailable = (event: { data: any }) => {
        audioChunksRef.current.push(event.data);
      };
      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: soundMimeType,
        });
        audioChunksRef.current = [];
        await sendAudioToApi(audioBlob);
        // Send the new audioBlob after stopping the recording
      };
      mediaRecorderRef.current.start();
      //setIsRecording(true);
    } catch (error) {
      console.error("Error accessing audio stream:", error);
    }
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    //setIsRecording(false);
  };
  //console.log("STATE", state);
  const sendAudioToApi = async (blob: any) => {
    try {
      const base64String = await convertBlobToBase64(blob);
      // const formattedBase64 = String(base64String).split(
      //   "data:audio/webm;codecs=opus;base64,"
      // )[1];

      const formattedBase64 = String(base64String)?.split(
        `data:${soundMimeType};base64,`
      )[1];

      if (formattedBase64) {
        const whisperTranscription = await fetchSTTWhisper(
          JSON.stringify({
            base64_audio: formattedBase64,
            //translate: state?.sttLanguage === "Enabled" ? true : false,
          })
        );

        if (state?.sttLanguage === "Enabled") {
          const translateBody = {
            target: state?.selectedLanguageJSON?.lang_code,
            text: whisperTranscription,
          };
          const translatedText = await TranslateWhisperTranslations(
            translateBody
          );
          if (translatedText?.status === 200) {
            setInputValue(translatedText?.data?.text);
          }
        } else {
          setInputValue(whisperTranscription);
        }

        //console.log("TRANSCRIPTION: ", whisperTranscription);

        //setTranscriptionValue(transcriptionValue + " " + whisperTranscription);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setTranslationAPIState("idle");
    }
  };

  const handleRecordingStart = () => {
    setAudioStates((prevData: any) => ({
      ...prevData,
      showMic: false,
      showStop: true,
      showSend: false,
    }));
    if (window.ReactNativeWebView) {
      setInputValue("");
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ action: "SPEECH_RECOGNITION_START" })
      );
    } else {
      setInputValue("");
      startRecording();
    }
  };

  const handleRecordingStop = async () => {
    setAudioStates((prevData: any) => ({
      ...prevData,
      showMic: true,
      showStop: false,
      showSend: true,
    }));
    setTranslationAPIState("start");
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ action: "SPEECH_RECOGNITION_STOP" })
      );
    } else {
      stopRecording();
    }
  };

  // Assuming you have a state or a way to update your UI with the received text
  function receiveTranscribedText(text: string) {
    // Update input value with the transcribed text
    setInputValue(text);

    //Send to API
    // sendSTT(text);
  }

  const navigateToURL = (url: string) => {
    window.location.href = url;
  };

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  // console.log("audioStates", audioStates);

  /*----------- SCROLL SUGGESTIONS BLOCK ------------- */

  const onMouseDown = (e: any) => {
    if (scrollRef.current) {
      setIsDragging(true);
      setStartX(e.pageX - scrollRef.current.offsetLeft);
      setScrollLeft(scrollRef.current.scrollLeft);
    }
  };

  const onMouseUp = () => {
    setIsDragging(false);
  };

  const onMouseMove = (e: any) => {
    if (!isDragging || !scrollRef.current) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 2; // The number 2 here multiplies the scroll speed
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };

  /*--------------  File Upload Functions ----------------------*/

  const handleFile = (file: any, fileType: string) => {
    handleUpload(file, fileType);
    //console.log("fileType", fileType);
  };
  //console.log("day: ", dayjs().format("YYYY-MMM-DD"));
  const handleUpload = async (file: any, fileType: string) => {
    try {
      //const dirName = dayjs().format("MMMM_YYYY");
      const storageRef = ref(
        storage,
        `/clinic-data-storage/clients/${currentUser?.Country}/${dayjs().format(
          "YYYY-MMM-DD_HH:mm:ss"
        )}_${file.name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);
      // console.log("fileType", fileType);

      setFileUploaded("uploading");
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          //console.log("Uploading...", percent);
        },
        (error) => {
          console.error("Upload error:", error);
        },
        async () => {
          try {
            const url = await getDownloadURL(uploadTask.snapshot.ref);

            if (fileType === "FILE") {
              setFileUploaded(fileUploadImage);
            } else {
              setFileUploaded(url);
            }
            setUploadedFileLink(url);
          } catch (error) {
            console.error("Error getting download URL:", error);
            // Handle error if needed
          }
        }
      );
    } catch (error) {
      console.error("Error while uploading");
      setFileUploaded("idle");
      setUploadedFileLink(null);
    }
  };

  //-----------------  Recieve Messages from React Native API --------------------------------

  // Make sure this function is accessible globally
  window.receiveTranscribedText = receiveTranscribedText;
  // console.log("smartSuggestionAreaRef", smartSuggestionAreaRef?.current?.clientHeight)
  return (
    <>
      {!state.isUserLoggedIn
        ? !token_url && !cookie_token && <NotLoggedInButton />
        : state.showInput && (
            <div className="max-w-[73rem] fixed bottom-0 left-0 md:left-1/2 md:-translate-x-1/2 w-full grid gap-0 z-10">
              <SmartSuggestionsArea suggestionsRef={smartSuggestionAreaRef} />

              <div className={chatTextAreaContainerClass}>
                <div className="flex flex-1 items-end gap-3 bg-transparent relative">
                  {!window.ReactNativeWebView && (
                    <UploadFileDrop handleFile={handleFile} />
                  )}

                  <div className="relative flex flex-grow items-stretch dark:bg-slate-900 rounded-[1rem] border border-solid border-slate-300 dark:border-slate-600">
                    {audioStates.showStop && (
                      <span className="absolute bottom-3.5 left-0 translate-x-0 -translate-y-1 flex h-3 w-3 my-auto ml-3">
                        <span
                          style={{ backgroundColor: "var(--primary)" }}
                          className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary/50 opacity-75"
                        ></span>
                        <span
                          style={{ backgroundColor: "var(--primary)" }}
                          className="relative inline-flex rounded-full h-3 w-3 bg-primary"
                        ></span>
                      </span>
                    )}

                    {translationAPIState !== "idle" ? (
                      <span className="absolute bottom-3.5 left-0 translate-x-0 -translate-y-1 flex h-3 w-3 my-auto ml-3">
                        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-700/50 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
                      </span>
                    ) : null}

                    <div className="flex flex-col w-full">
                      {fileUploaded !== "idle" && (
                        <div className="p-2 w-full border-b border-solid border-slate-300">
                          <div className="w-12 h-auto aspect-square rounded-md border bg-slate-300/80 relative flex items-center justify-center">
                            {fileUploaded == "uploading" && (
                              <LoaderCircle className="w-6 h-6 animate-spin text-white" />
                            )}
                            {fileUploaded !== "idle" &&
                              fileUploaded !== "uploading" &&
                              uploadedFileLink && (
                                <>
                                  <button
                                    className="p-0.5 rounded-full aspect-square bg-white border absolute -top-1 -right-1"
                                    onClick={() => {
                                      setFileUploaded("idle");
                                      setUploadedFileLink(null);
                                    }}
                                  >
                                    <X className="w-3.5 h-3.5 dark:text-black" />
                                  </button>
                                  <img
                                    src={fileUploaded}
                                    className="w-12 h-12 object-cover rounded-md"
                                  />
                                </>
                              )}
                          </div>
                        </div>
                      )}

                      {/* DESKTOP  */}
                      <textarea
                        ref={inputRefDesktop}
                        rows={1}
                        disabled={audioStates.showStop}
                        name="text"
                        id="messageSend"
                        autoFocus
                        autoComplete="off"
                        value={inputValue}
                        onBlur={(e) => {
                          e.target.focus();
                        }}
                        onChange={(e) => handleInputChange(e)}
                        onKeyDown={handleKeyPress}
                        className={`${chatInputClass} rounded-full w-full resize-none hidden md:block ${
                          audioStates.showStop || translationAPIState !== "idle"
                            ? "pl-8"
                            : "pl-3 "
                        }`}
                        placeholder={
                          translationAPIState === "idle"
                            ? audioStates?.showStop
                              ? translate("STTPlaceholder")
                              : translate("MessagePlaceholder")
                            : translate("TranscribingYourSpeech")
                        }
                      />

                      {/* Mobile Version */}
                      <textarea
                        ref={inputRef}
                        rows={1}
                        name="text"
                        id="messageSend"
                        autoComplete="off"
                        value={inputValue}
                        onChange={(e) => handleInputChange(e)}
                        onKeyDown={handleKeyPress}
                        className={`${chatInputClass} resize-none md:hidden ${
                          audioStates.showStop ? "pl-8" : "pl-3 "
                        }`}
                        placeholder={
                          window.ReactNativeWebView
                            ? audioStates?.showStop
                              ? translate("STTPlaceholder")
                              : translate("MessagePlaceholder")
                            : translationAPIState === "idle"
                            ? audioStates?.showStop
                              ? translate("STTPlaceholder")
                              : translate("MessagePlaceholder")
                            : translate("TranscribingYourSpeech")
                        }
                      />
                    </div>
                  </div>
                  {/* EXPERIMENTAL FOR WHISPER */}
                  {/* {
                !isListening && (
                  <button
                  className={microphoneButtonClass}
                  onClick={startRecording_EXPERIMENTAL}
                >
                  <Mic className="text-slate-600 dark:text-white w-5 h-5" />
                </button>
                )
              }

               {isListening && (
                <button
                  className={stopRecordingButtonClass}
                  onClick={stopRecording_EXPERIMENTAL}
                >
                  <Square className="text-white w-5 h-5" />
                </button>
              )} */}
                  {audioStates.showMic &&
                    browserSupportsSpeechRecognition &&
                    appPartner !== "bajaj" && (
                      <button
                        className={microphoneButtonClass}
                        onClick={handleRecordingStart}
                      >
                        <Mic className="text-slate-600 dark:text-white w-5 h-5" />
                      </button>
                    )}

                  {audioStates.showStop && (
                    <button
                      style={{ backgroundColor: "var(--primary)" }}
                      className={stopRecordingButtonClass}
                      onClick={handleRecordingStop}
                    >
                      <Square className="text-white w-5 h-5" />
                    </button>
                  )}
                </div>
                {audioStates.showSend && (
                  <button
                    type="button"
                    style={{
                      backgroundColor:
                        inputValue.trim() == "" && !uploadedFileLink
                          ? ""
                          : "var(--primary)",
                    }}
                    className={sendChatButtonClass}
                    onClick={onEnterClicked}
                    disabled={
                      inputValue.trim() == "" && !uploadedFileLink
                        ? true
                        : false
                    }
                  >
                    <Send className="mx-auto w-5 h-5" />
                  </button>
                )}
              </div>
              {/* {audioStates.showStop && (
              <div className="h-[27vh] bg-primary-dark relative flex items-center justify-center md:hidden p-3">
                <span className="relative flex items-center justify-center w-max h-auto aspect-square">
                  <span className="animate-ping absolute inline-flex h-24 w-24 rounded-full bg-sky-400 opacity-75"></span>
                  <button
                    className="relative flex items-center justify-center gap-2 rounded-full h-36 w-36 bg-indigo-400"
                    onClick={handleRecordingStop}
                  >
                    <div className="bg-white p-1.5 rounded-full w-max">
                      <Square className="text-slate-600  w-3 h-3" />
                    </div>
                    <span className="text-white text-sm font-medium">
                      Stop recording
                    </span>
                  </button>
                </span>
              </div>
            )} */}
            </div>
          )}
    </>
  );
};

export default ChatTextInputArea;
