import React, { useRef, useState } from "react";
import ButtonSuggestionItem from "../suggestions/ButtonSuggestionItem";
import SuggestionItem from "../suggestions/SuggestionItem";
import { ChevronLeftIcon } from "lucide-react";
import { ChevronRightIcon } from "lucide-react";

const SmartSuggestionsArea = ({ suggestionsRef }: any) => {
  const scrollRefRow1 = useRef<HTMLDivElement>(null);
  const scrollRefRow2 = useRef<HTMLDivElement>(null);

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [startX, setStartX] = useState<number>(0);
  const [scrollLeft, setScrollLeft] = useState<number>(0);

  const [isDraggingRow2, setIsDraggingRow2] = useState<boolean>(false);
  const [startXRow2, setStartXRow2] = useState<number>(0);
  const [scrollLeftRow2, setScrollLeftRow2] = useState<number>(0);

  const scrollAmount = 200; // Define how much to scroll when clicking the arrows

  /*----------- SCROLL SUGGESTIONS BLOCK ------------- */

  const onMouseDown = (e: any) => {
    if (scrollRefRow1.current) {
      setIsDragging(true);
      setStartX(e.pageX - scrollRefRow1.current.offsetLeft);
      setScrollLeft(scrollRefRow1.current.scrollLeft);
    }
  };

  const onMouseUp = () => {
    setIsDragging(false);
  };

  const onMouseMove = (e: any) => {
    if (!isDragging || !scrollRefRow1.current) return;
    e.preventDefault();
    const x = e.pageX - scrollRefRow1.current.offsetLeft;
    const walk = (x - startX) * 2; // The number 2 here multiplies the scroll speed
    scrollRefRow1.current.scrollLeft = scrollLeft - walk;
  };

  const onMouseDownRow2 = (e: any) => {
    if (scrollRefRow2.current) {
      setIsDraggingRow2(true);
      setStartXRow2(e.pageX - scrollRefRow2.current.offsetLeft);
      setScrollLeftRow2(scrollRefRow2.current.scrollLeft);
    }
  };

  const onMouseUpRow2 = () => {
    setIsDraggingRow2(false);
  };

  const onMouseMoveRow2 = (e: any) => {
    if (!isDraggingRow2 || !scrollRefRow2.current) return;
    e.preventDefault();
    const x = e.pageX - scrollRefRow2.current.offsetLeft;
    const walk = (x - startXRow2) * 2; // The number 2 here multiplies the scroll speed
    scrollRefRow2.current.scrollLeft = scrollLeftRow2 - walk;
  };

  // Function to scroll rows programmatically
  const scrollToLeft = (row: number) => {
    const scrollRef = row === 1 ? scrollRefRow1.current : scrollRefRow2.current;
    if (scrollRef) {
      scrollRef.scrollBy({ left: -scrollAmount, behavior: "smooth" });
    }
  };

  const scrollToRight = (row: number) => {
    const scrollRef = row === 1 ? scrollRefRow1.current : scrollRefRow2.current;
    if (scrollRef) {
      scrollRef.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  return (
    <>
      {/* SUGGESTIONS AREA HIDDEN FOR NOW*/}
      {/* Remove class hidden */}
      <div
        ref={suggestionsRef}
        className=" w-screen max-w-[73rem] px-2 md:px-16 mx-auto bg-white dark:bg-[#020611] -mb-0.5 z-1 border-t dark:border-slate-700 md:border-none relative hidden"
      >
        <h6 className="dark:text-white font-medium p-2">
          What do you want to do?
        </h6>
        {/* <div
          className="overflow-x-auto gap-3 flex w-full px-2 py-2 suggestionScroll"
          ref={scrollRefRow1}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseLeave={onMouseUp}
          onMouseMove={onMouseMove}
          style={{ cursor: isDragging ? "grabbing" : "grab" }}
        >


          <SuggestionItem type={"Video"} />
          <SuggestionItem type={"Video"} />
          <SuggestionItem type={"Expert"} />

          
        </div> */}

        {/* Mood Area */}
        {/* <MoodSuggestionItem /> */}
        <button
          className="absolute left-2 md:left-6 top-[2.9rem] z-10 bg-gray-200 dark:bg-gray-700 p-2 rounded-full hidden md:block"
          onClick={() => scrollToLeft(2)}
        >
          <ChevronLeftIcon className="h-6 w-6 text-gray-500" />
        </button>
        <div
          className="overflow-x-auto gap-3 flex w-full px-2 py-2 suggestionScroll"
          ref={scrollRefRow2}
          //onMouseDown={onMouseDownRow2}
         // onMouseUp={onMouseUpRow2}
         // onMouseLeave={onMouseUpRow2}
         // onMouseMove={onMouseMoveRow2}
          //style={{ cursor: isDraggingRow2 ? "grabbing" : "grab" }}
        >
          <ButtonSuggestionItem text={"Add Name"} />
          <ButtonSuggestionItem text={"Add Age"} />
          <ButtonSuggestionItem text={"Add Address"} />
          <ButtonSuggestionItem text={"Add Name"} />
          <ButtonSuggestionItem text={"Add Age"} />
          <ButtonSuggestionItem text={"Add Address"} />
          <ButtonSuggestionItem text={"Add Name"} />
          <ButtonSuggestionItem text={"Add Age"} />
          <ButtonSuggestionItem text={"Add Address"} />
          <ButtonSuggestionItem text={"Add Name"} />
          <ButtonSuggestionItem text={"Add Age"} />
          <ButtonSuggestionItem text={"Add Address"} />
        </div>

        <button
          className="absolute right-2 md:right-6 top-[2.9rem] z-10 bg-gray-200 dark:bg-gray-700 p-2 rounded-full hidden md:block"
          onClick={() => scrollToRight(2)}
        >
          <ChevronRightIcon className="h-6 w-6 text-gray-500" />
        </button>
      </div>
    </>
  );
};

export default SmartSuggestionsArea;
